import MuiInput from './MuiInput.js'
// import MuiInput from './MuiInput'
import MuiAutocomplete from './MuiAutocomplete'
import MuiRadioGroup from './MuiRadioGroup'
import MuiCheckboxes from './MuiCheckboxes'
import { MuiPasswordField } from './MuiPasswordField'
import { MuiSwitch } from './MuiSwitch'
import { MuiSelect } from './MuiSelect'
import { AntdDatePicker } from './AntdDatePicker'
import { AntdDateRangePicker } from './AntdDateRangePicker'
import MuiButton from './MuiButton'
import MuiUploadImage from './MuiUploadImage'
//import MuiFileUpload from './MuiFileUpload'
import TextEditor from './TextEditor'

function FormikControl(props) {
    const { control, ...rest } = props
    switch (control) {
        case 'textfield':
            return <MuiInput {...rest} />
        case 'autocomplete':
            return <MuiAutocomplete {...rest} />
        case 'radiogroup': 
            return <MuiRadioGroup {...rest} />
        case 'ckeckbox':
            return <MuiCheckboxes {...rest} />
        case 'passwordfield':
            return <MuiPasswordField {...rest} />
        case 'switch':
            return <MuiSwitch {...rest}/>
        case 'select':
            return <MuiSelect {...rest}/>
        case 'datepicker':
            return <AntdDatePicker {...rest} />
        case 'daterangepicker':
            return <AntdDateRangePicker {...rest}/>
        case 'uploadImage':
            return <MuiUploadImage {...rest} />
        case 'button':
            return <MuiButton {...rest}/>
        // case 'fileupload':
        //     return <MuiFileUpload {...rest}/>
        case 'richTextEditor':
            return <TextEditor {...rest}/>
        default: return null


    }
}

export default FormikControl