import Swal from 'sweetalert2'

export default function showAlert(title: any, message: any, msgType: any, buttonText: any) {

    let alertData;
    if (buttonText) {
        alertData = {
            title: title,
            text: message,
            icon: msgType,
            confirmButtonText: buttonText,
            timer: 4000
        };
    } else {
        alertData = {
            title: title,
            text: message,
            icon: msgType,
            timer: 4000
        };
    }
    Swal.fire(alertData);
}


export const conformationAlert = async(message: string, alertTypeIcon: any) => {
    let flag = false;
    await Swal.fire({
        title: message,
        html: "<strong>Do you want to " + message + "</strong>",
        icon: alertTypeIcon,
        showCancelButton: true,
        cancelButtonText: 'No! Close',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes! Update',
        confirmButtonColor: '#50cd89'
    }).then((result: { isConfirmed: any; }) => {
        if (result.isConfirmed) {
            flag = true;
        }else{
            flag = false;
        }
    })
    return flag;
}