export class ItemScreenModel{
    images:{}[];
    timings:string[];
    category_id:number;
    title:string;
    price:string;
    description:string
    qty:number;
    id:string;
    itemavailability:boolean;
    constructor(){
        this.images=[{}];
        this.title="";
        this.price="";
        this.description="";
        this.itemavailability=true;
        this.timings=[];
        this.category_id=123456;    
        this.qty=1;
        this.id="";
    }
}