export const RouteType = {
    FILE: "/file",
    ADMIN: "/admin",
    AUTH: "/auth",
    LOGIN: "/login",
    PRODUCT: "/product",
    REGISTER: "/register",
    CATEGORY: "/category",
    
    //Crud routes
    STORE: "/store",
    DELETE: "/delete",
 
    GET_COUNTRY: '/get-country',
    GET_STATE: '/get-state',
    GET_CITY: '/get-city',
   
   
   
  };