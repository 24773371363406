import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Avatar, Box, Button, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import { LoginModel } from "../model/customModel/LoginModel";
import { AuthService } from "../services/auth/AuthService";
import { LoginSchema } from "../schema/LoginSchema";
import TextError from "./TextError";
import './styles/LoginScreen.css';
import FormikControl from "../components/wrapperComponents/FormikControl";
import { LocalStorageService } from "../services/auth/LocalStorageService";

const LoginScreen = () => {
    // const [logo, setLogo] = useState("./Assets/login_person.png");
    const logo = require("../assests/images/login_person.png");
    
    const [capVal, setCapVal] = useState(null);
    const [initialValue, setInitialValue] = useState(new LoginModel());
    
    const navigate = useNavigate();
    const prepareData=(values:LoginModel)=>{
      const data=new LoginModel();
      data.email=values.email?values.email:'';
      data.password=values.password?values.password:'';
      return data;
    }

    const check = async (values: LoginModel)=>{  
      const requestData=await prepareData(values);
      const response=await AuthService.autanticateUser(requestData);
       //alert(response);
      if(response.statusMsg === 'SUCCESS'){
        console.log(response.data.authorization.token);
        LocalStorageService.setToken(response.data.authorization.token);
    // if (values.email === 'test@gmail.com' && values.userpassword === '123') {
        // Navigate to next page
        // setErrors({ username: 'Ready to Switch....' });
        // navigate("/register") ;

        navigate("/dashboard");        
      } else {
        // setErrors({ username: 'Invalid email or password' });
        alert("Some problem");
      }
  };
  
  const [recaptchaChecked,setRecaptchaChecked]=useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {setShowPassword((show) => !show)};
  const handleMouseDownPassword = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };
  const recaptchaChecking = () => {
    setRecaptchaChecked(true);
  }
  
  return(
  <div className="bodyLogin">
    <div className='wrapperLogin'>
      <Formik
         initialValues={initialValue}
         validationSchema={LoginSchema}
         
          onSubmit={check}
    //       onSubmit={values => {
    //         alert(values.email);
    // //redirect("/register");
    // navigate("/register") ;
    //       }}
       >
         {({ isSubmitting, errors, isValid, dirty }) => (
          
           <Form>
            
             
             <div className="mycenter">
                 <img className="logo" src="/images/logo1.png" alt="" />
               </div>
              <h1>Login</h1>
              <div style={{marginBottom:20}}></div>
              
              <Grid container spacing={2} className='design'>
                    <Grid item lg={12} sm={12} md={12} >
                        <FormikControl control="textfield"
                        label="Email Id"
                        requiredField
                        name="email"
                        placeholder="Enter email id" 
                        size="small"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} className='design'>
                    <Grid item lg={12} sm={12} md={12} >
                        <FormikControl control="passwordfield"
                            label="Password"
                            requiredField
                            name="password"
                            placeholder="Enter password"
                            size="small"
                            />
                    </Grid>
                </Grid>


              {/* <Field name="email" >
              {({ field }) => {
                console.log(field.errors)
                return (
                  <React.Fragment>
                    <TextField id="email" {...field}
                      sx={{ padding: 1, }}
                      type="email"
                      fullWidth
                      size='small'
                      autoComplete='off'
                      placeholder="email id"
                    />
                    <Box className='error-box'>
                      <ErrorMessage name="email" component={"div"} />
                    </Box>
                    </React.Fragment>
                );
              }}
              </Field>  
  
        
              
            
              <Field name="userpassword" >
              {({ field }) => {
              
                return (
                  <React.Fragment>
                    <FormControl sx={{ padding:1,  }} fullWidth  variant="outlined">
                    <OutlinedInput
                    id="userpassword" {...field}
                    type={showPassword ? 'text' : 'password'}
                    size='small'
                    placeholder="Password"
                    
                    endAdornment={
                      <InputAdornment position="end" fullWidth component={TextField}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                    </FormControl> 
              
                    <Box className='error-box'>
                      <ErrorMessage name="userpassword" component={"div"} />
                    </Box>
                    </React.Fragment>
                );
              }}
              </Field>   */}
            
            <Grid container spacing={2} className='design'>
                    <Grid item lg={12} sm={12} md={12} >
                        <ReCAPTCHA
              sitekey="6LdpfBwqAAAAADWHT5IO9K7dZNBn9xFonzkZ8fGp"
              onChange={recaptchaChecking}
              className='rc-anchor-light mycenter rc-anchor-normal'
              />
              </Grid>
              </Grid>
             
            <div className="recaptcha" style={{
              margin: 0,
              position: "relative",
              top: 10,
              left:10,
              alignItems:"center"
            }}>
          <div  className="mycenter">
            <Button type="submit" className="btn btn-submit" variant="contained"  disabled={!dirty || !isValid || !recaptchaChecked}> 
                  Submit
            </Button> 
             
             </div>
             </div>
                  
           </Form>
    )}
    </Formik>
      < div className="register-link">
        <p> New User? <Link to="/register" className="CustomLink">Click here</Link></p>
      </div>
    </div>
    </div>  
  );
  
  };
  
  export default LoginScreen;