import React from 'react'
import { Link } from 'react-router-dom'
import './SideMenuBar.scss'

export default function SideMenuBar() {
  return (
    <div>
      {/* <!--begin::Aside--> */}
      <div className="aside aside-left  aside-fixed  d-flex flex-column flex-row-auto" id="kt_aside">
        {/* <!--begin::Brand--> */}
        <div className="brand flex-column-auto " id="kt_brand">
          {/* <!--begin::Logo--> */}
          <a href="/dashboard" className="brand-logo">
            <img src="./images/logo1.png" alt="SalesWays Logo" className="img-circle brand-image elevation-3" />
          </a>
          {/* <!--end::Logo--> */}

          {/* <!--begin::Toggle--> */}
          <button className="brand-toggle btn btn-sm px-0 toggle_btn" id="kt_aside_toggle">
            <span className="svg-icon svg-icon svg-icon-xl">
              {/* <en className="la la-remove"></en> */}
            </span> </button>
          {/* <button className="brand-toggle btn btn-sm px-0 toggle_btn" id="kt_aside_toggle" onClick="closeSubmit()">
            <span className="svg-icon svg-icon svg-icon-xl">
            </span> </button> */}
          {/* <!--end::Toolbar--> */}
        </div>
        {/* <!--end::Brand--> */}

        {/* <!--begin::Aside Menu--> */}
        <div className="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
          {/* <!--begin::Menu Container--> */}
          <div id="kt_aside_menu" className="aside-menu " data-menu-vertical="1" data-menu-scroll="1" data-menu-dropdown-timeout="500">
            {/* <!--begin::Menu Nav--> */}
            <ul className="menu-nav ">
              <li className="menu-item" aria-haspopup="true">
                <a href="/dashboard" className="menu-link ">
                  <span className="svg-icon menu-icon">
                    {/* <en className="la la-dashboard"></en> */}
                  </span><span className="menu-text">Dashboard</span>
                </a>
              </li>
              <li className="menu-item menu-item-submenu" data-menu-toggle="hover ">
                <Link to="category">Category</Link>
               
                <div className="menu-submenu ">
                  {/* <en className="menu-arrow "></en> */}
                  <ul className="menu-subnav ">
                    <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
                      <a href="javascript:;" className="menu-link menu-toggle">
                        {/* <en className="menu-bullet menu-bullet-dot"></en> */}
                        <span className="menu-text">Salary</span>
                        {/* <en className="menu-arrow"></en> */}
                      </a>
                      <div className="menu-submenu" kt-hidden-height="120">
                        {/* <en className="menu-arrow"></en> */}

                        <ul className="menu-subnav menu-item-active">
                          <li className="menu-item" aria-haspopup="true">
                            <a href="{% url 'hr.salary-structure' %}" className="menu-link ">
                              {/* <en className="menu-bullet menu-bullet-dot"><span></span></en> */}
                              <span className="menu-text">Salary Structure</span></a>
                          </li>
                          <li className="menu-item" aria-haspopup="true">
                            <a href="{% url 'hr.salary-sheet' %}" className="menu-link ">
                              {/* <en className="menu-bullet menu-bullet-dot"><span></span></en> */}
                              <span className="menu-text">Salary Sheet</span></a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="menu-item" aria-haspopup="true">
                      <a href="#" className="menu-link ">
                        {/* <en className="menu-bullet menu-bullet-dot"><span></span></en> */}
                        <span className="menu-text">Leave Policy</span></a>
                    </li>
                    <li className="menu-item" aria-haspopup="true">
                      <a href="#" className="menu-link ">
                        {/* <en className="menu-bullet menu-bullet-dot"><span></span></en> */}
                        <span className="menu-text">Attendance</span></a>
                    </li>
                    <li className="menu-item" aria-haspopup="true">
                      <a href="#" className="menu-link ">
                        {/* <en className="menu-bullet menu-bullet-dot"><span></span></en> */}
                        <span className="menu-text">Holidays</span></a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover">
                <a href="javascript:; " className="menu-link menu-toggle ">
                  <span className="svg-icon menu-icon ">
                    {/* <en className="la la-user-friends"></en> */}
                  </span>
                  <span className="menu-text ">Employee Management</span>
                  {/* <en className="menu-arrow "></en> */}
                </a>
                <div className="menu-submenu" kt-hidden-height="120">
                  {/* <en className="menu-arrow"></en> */}
                  <ul className="menu-subnav">
                    <li className="menu-item">
                      <a href="{% url 'hr.employee' %}" className="menu-link ">
                        {/* <en className="menu-bullet menu-bullet-dot "><span></span></en> */}
                        <span className="menu-text ">Employee List</span></a>
                    </li>
                    <li className="menu-item">
                      <a href="{% url 'hr.department' %}" className="menu-link ">
                        {/* <en className="menu-bullet menu-bullet-dot "><span></span></en> */}
                        <span className="menu-text ">Department</span></a>
                    </li>
                    <li className="menu-item">
                      <a href="{% url 'hr.designation' %}" className="menu-link ">
                        {/* <en className="menu-bullet menu-bullet-dot "><span></span></en> */}
                        <span className="menu-text ">Designation</span></a>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu-item menu-item-submenu" data-menu-toggle="hover ">
                <a href="javascript:; " className="menu-link menu-toggle ">
                  <span className="svg-icon menu-icon ">
                    {/* <en className="la la-exchange"></en> */}
                  </span>
                  <span className="menu-text ">Payment</span>
                  {/* <en className="menu-arrow "></en> */}
                </a>
                <div className="menu-submenu ">
                  {/* <en className="menu-arrow "></en> */}
                  <ul className="menu-subnav ">
                    <li className="menu-item">
                      <a href="{% url 'payment.inward'%}" className="menu-link ">
                        {/* <en className="menu-bullet menu-bullet-dot "><span></span></en> */}
                        <span className="menu-text ">Inward Payment </span></a>
                    </li>
                    <li className="menu-item">
                      <a href="{% url 'payment.outward'%}" className="menu-link ">
                        {/* <en className="menu-bullet menu-bullet-dot "><span></span></en> */}
                        <span className="menu-text ">Outward Payment</span></a>
                    </li>
                  </ul>
                </div>
              </li>


              <li className="menu-item" aria-haspopup="true">
                <a href="#" className="menu-link ">
                  <span className="svg-icon menu-icon ">
                    {/* <en className="la la-user"></en> */}
                  </span>
                  <span className="menu-text">Customer</span>
                </a>
              </li>

              <li className="menu-item ">
                <a href="/logout " className="menu-link ">
                  <span className="svg-icon menu-icon ">
                    {/* <en className="la la-undo"></en> */}
                  </span><span className="menu-text ">Logout</span>
                </a>
              </li>
            </ul>
            {/* <!--end::Menu Nav--> */}
          </div>
          {/* <!--end::Menu Container--> */}
        </div>
      </div>
    </div>

  )
};
