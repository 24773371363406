import { Box, Switch } from '@mui/material';
import { Field } from 'formik';
import React from 'react'
import MuiLabel from './MuiLabel';
import { Padding } from '@mui/icons-material';

export const MuiSwitch = (props) => {
  const {name, ...rest} = props;
  return (
    <Field name={name} {...rest}>
        {
            ({field})=>{
              const {value} = field
                return(
                    <React.Fragment>
                        {props.label ? <MuiLabel {...props} />: null}
                        <Box padding={2}>
                         <Switch 
                         {...rest}
                         {...field}
                         checked={value}
                         />
                         </Box>
                    </React.Fragment>
                )
            }
        }

    </Field>
  )
}
