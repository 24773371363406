export class CategoryScreenModel{
    title: string;
    image: string;
    description: string;
    short_description: string;
    meta_description: string;
    show_in: string;
    id: string;

    category_availability: string;
    index:number;

    constructor(){
        this.title="";    
        this.image="";
        this.description="";
        this.short_description="";
        this.meta_description="xxxxxxxx";
        this.show_in="Footer";
        this.id="";

        this.category_availability="";
        this.index=0;
    }
}