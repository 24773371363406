import * as Yup from "yup";
import { msgTypes } from "../constants/msgTypes";
import { Validation } from "../constants/validation";

// const type="'png', 'jpg', jpeg";
    
export const CategoryScreenSchema = Yup.object().shape({
    title: Yup.string().required("Enter category name"),
    description: Yup.string().required("Enter description"),
    short_description: Yup.string().required("Enter sort description"),
    image: Yup.mixed().nullable().required(Validation.required.message.replaceAll("^","Image"))
    .test(
        "FILE_FORMAT",
        " file has unsupported format",
        (value:any) => !value || (value && msgTypes.SUPPORTED_IMAGE_FORMAT.includes(value?.type))
    )
    .test(
        "FILE_SIZE",
        "Uploaded file is too big",
        (value:any) =>!value || (value && value.size <=1024 * 1024)
    )
})