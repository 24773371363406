// import { convertToRaw, EditorState } from "draft-js";
// import { useState } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// // import draftToHtml from "draftjs-to-html";
// import { Fragment } from "react";

export default function RichTextEditor() {
  return (<></>);
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [text, setText] = useState();
  // const onEditorStateChange = function (editorState) {
  //   setEditorState(editorState);
  //   const { blocks } = convertToRaw(editorState.getCurrentContent());
  //   /*let text = blocks.reduce((acc, item) => {
  //     acc = acc + item.text;
  //     return acc;
  //   }, "");*/
  //   let text = editorState.getCurrentContent().getPlainText("\u0001");
  //   setText(text);
  // };

  // return (
  //   <>
  //     {/*<div>{draftToHtml(convertToRaw(editorState.getCurrentContent()))}</div>*/}
  //     <Editor
  //       editorState={editorState}
  //       toolbarClassName="toolbarClassName"
  //       wrapperClassName="wrapperClassName"
  //       editorClassName="editorClassName"
  //       onEditorStateChange={onEditorStateChange}
  //     />
  //   </>
  // );
}
