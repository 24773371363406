import { alpha, Box, Checkbox, FormControlLabel, Grid, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { visuallyHidden } from '@mui/utils';

import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AuthService } from '../services/auth/AuthService';
import { CategoryScreenModel } from '../model/customModel/CategoryScreenModel';
import { CategoryListSCreenModel } from '../model/customModel/CategoryListScreenModel';
import ApiUrl from '../services/shared/ApiUrl';
import { msgTypes } from '../constants/msgTypes';

interface Data {
    id: number;
    image_path: string;
    // carbs: number;
    short_desc: string;
    name: string;
    // protein: number;
  }
  
  

  function createData(
    id: number,
    name: string,
    image_path: string,
    short_desc: string,
    // carbs: number,
    // protein: number,
  ): Data {
    return {
      id,
      name,
      image_path,
      short_desc,
      // carbs,
      // protein,
    };
  }
  
  let rows = [
    createData(1, 'Cupcake', '', ''),
    // createData(2, 'Donut', '452', 25.0, 51, 4.9),
    // createData(3, 'Eclair', '262', 16.0, 24, 6.0),
    // createData(4, 'Frozen yoghurt', '159', 6.0, 24, 4.0),
    // createData(5, 'Gingerbread', '356', 16.0, 49, 3.9),
    // createData(6, 'Honeycomb', '408', 3.2, 87, 6.5),
    // createData(7, 'Ice cream sandwich', '237', 9.0, 37, 4.3),
    // createData(8, 'Jelly Bean', '375', 0.0, 94, 0.0),
    // createData(9, 'KitKat','518', 26.0, 65, 7.0),
    // createData(10, 'Lollipop', '392', 0.2, 98, 0.0),
    // createData(11, 'Marshmallow', '318', 0, 81, 2.0),
    // createData(12, 'Nougat', '360', 19.0, 9, 37.0),
    // createData(13, 'Oreo', '437', 18.0, 63, 4.0),
  ];
  

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  type Order = 'asc' | 'desc';
  
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
   
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }
  
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'image_path',
      numeric: true,
      disablePadding: false,
      label: 'Image',
    },
    {
      id: 'short_desc',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    // {
    //   id: 'carbs',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Carbs (g)',
    // },
    // {
    //   id: 'protein',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Protein (g)',
    // },
  ];
  
  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    selectedId: number[];
  }
  
  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, selectedId } =
      props;
    console.log(selectedId);  
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  interface EnhancedTableToolbarProps {
    numSelected: number;
    selectedIds: number[];
  }

  const handleClickForDelete = (event: React.MouseEvent<unknown>, numSelected: number, selectedIds: number[]) => {
    alert("delete?"+numSelected);
    
    selectedIds.map(data=>{
      // const object = new CategoryListSCreenModel();
      const object = new CategoryScreenModel();
      object.id = ""+data;
        deleteCategory(object);
    });
    
  }
  
  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;
    const { selectedIds } = props;
    
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Category List
          </Typography>
        )}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon onClick={(event) => handleClickForDelete(event, numSelected, selectedIds)}/>
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }

  // const deleteCategory = async (values: CategoryListSCreenModel )=>{
    const deleteCategory = async (values: CategoryScreenModel )=>{
      const response = await AuthService.deleteCategory(values);
    if(response.statusMsg==="SUCCESS"){
      window.location.reload();
    }else {
      alert("Some problem");
    }
  }



  

export const CategoryListScreen = () => {
    const [baseUrl, setBaseUrl] = React.useState(ApiUrl.fileBaseUrl);
    const [selectedOrders, setSelectedOrders] = React.useState<number[]>([]);
  
    const [orderName, setOrderName] = React.useState('');
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('image_path');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    useEffect(()=>{
        loadCategoryDetails();
        },[]);
    const loadCategoryDetails = async ()=>{
        const response = await AuthService.getCategorylist(msgTypes.EMPTY_STR);
        // alert("ok");
        if(response.statusMsg === 'SUCCESS'){
            rows = [response.data.length];
            for(let i=0;i<response.data.length;i++){
                console.log(response.data[i].title);
                rows[i]=createData(response.data[i].id, response.data[i].title, baseUrl+response.data[i].image, response.data[i].short_description);
                console.log(rows[i]);
            }
           //alert(response.data);
            }
    }    
    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: keyof Data,
    ) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };
    
    // const tempId: React.SetStateAction<number[]>=[];
      
    const handleClick = (event: any, id: number, status: boolean) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly number[] = [];  
      console.log(event.isSelected);
      console.log(status);
      // setDatas(datas=>({
        //   ...datas,      [index]: val.target.value
        // }))
      // setSelectedOrders(selectedOrders=>({...selectedOrders,id}));
      // setSelectedOrders(selectedOrders=>{console.log(selectedOrders)});
      // let selectedValue=selectedOrders;
      // selectedValue.push(id);

      // const temp=[{
      //     "id":1,
      //     "status":false
      // },
      // {
      //   "id":2,
      //   "status":true
      // }
      // ];
      // const temp1=temp.filter(data=>{
      //   return data.status===true
      // })
      // setSelectedOrders(temp1.map(data=>{return data.id}));

      // setSelectedOrders(prevState => [...prevState, id]);
      
      
      //alert("handleClick-> id=" + id+" , selectedIndex="+selectedIndex);

      if(status===false){
        setSelectedOrders(prevState => [...prevState, id]);
        console.log(selectedOrders);
      }else{
        setSelectedOrders(oldValues => {
          return oldValues.filter(fruit => fruit !== id)
        })
      }

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
        setOrderName(""+selectedIndex);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    };
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDense(event.target.checked);
    };
  
    const isSelected = (id: number) => selected.indexOf(id) !== -1;
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
    const visibleRows = React.useMemo(
      () =>
        stableSort(rows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
      [order, orderBy, page, rowsPerPage],
    );

    return (
        
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={2} className='designHeader'>
                    <Grid item lg={6} sm={12} md={12} >
                    <h1 className="header-title">Category List</h1>
                    </Grid>
                </Grid>

          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} selectedIds={selectedOrders} />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  selectedId={selectedOrders}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
    
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id, isItemSelected)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}

                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                        {row.name}
                        </TableCell>
                        <TableCell align="right"><img src={row.image_path} width="100px"/></TableCell>
                        <TableCell align="left">{row.short_desc}</TableCell>
                        {/* <TableCell align="right">{row.carbs}</TableCell>
                        <TableCell align="right">{row.protein}</TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </Box>
      );
    
}
