export class RegistrationScreenModel{
    name:string;
    address:string;
    state:string;
    zip:string;
    mobile:string;
    email:string;
    password:string;
    confirmpassword:string;
    constructor(){
        this.name="";
        this.address="";
        this.state="";
        this.zip="";
        this.mobile="";
        this.email="";
        this.password="";
        this.confirmpassword="";

    }
}