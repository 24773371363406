import React, { useEffect, useState } from 'react'
import FormikControl from "../components/wrapperComponents/FormikControl";
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import "./styles/CategoryScreen.css";
import { Navigate, useNavigate } from 'react-router-dom';
import { CategoryScreenModel } from '../model/customModel/CategoryScreenModel';
import { CategoryScreenSchema } from '../schema/CategoryScreenSchema';
import { AuthService } from '../services/auth/AuthService';
import { UploadFileService } from '../services/shared/UploadFileService';
import { resetWarned } from 'antd/es/_util/warning';

export const CategoryScreen = (props:any) => {
    const {setIndex, updateRecord} = props;
    const [initialValue, setInitialValue] = useState(new CategoryScreenModel());
    
    useEffect(() => {
        if (updateRecord) {
          setInitialValue(updateRecord)
        }
      }, [updateRecord])

    const timeList = [
        {"name":"Breakfast", "timeIdentifier":"1"}, 
        {"name":"Lunch", "timeIdentifier":"2"}, 
        {"name":"Snacks", "timeIdentifier":"3"}, 
        {"name":"Dinner", "timeIdentifier":"4"}];
    const categoryList = [
            {"name":"Thali", "categoryIdentifier":"234123444"}, 
            {"name":"Byriyani", "categoryIdentifier":"345222654"}, 
            {"name":"Coffee", "categoryIdentifier":"922658625"}, 
            {"name":"Paneer Sabji", "categoryIdentifier":"345551289"}];

    const navigate = useNavigate();
    const prepareData = async (values:CategoryScreenModel)=>{  
        const data=new CategoryScreenModel();
        // data.image=values.image?values.image:'';
        data.title=values.title?values.title:'';
        data.description=values.description?values.description:'';
        data.short_description=values.short_description?values.short_description:'';
        // data.meta_description=values.meta_description?values.meta_description:'';
        // data.show_in=values.show_in?values.show_in:'';
        data.id=values.id?values.id:'';

        // if(values.image.length>0){
            if (values.image) {
                const formData = new FormData();
                formData.append("file", values.image);
                const imageurlData = await UploadFileService.getImagePath(formData);
                // data.images.push(imageurlData.data.fileName);
                data.image=imageurlData.data.fileName;
            } else {
                data.image = '';
            }
        // }

        return data;
    }
    const checkAndSend = async (values: CategoryScreenModel)=>{
        const requestData = await prepareData(values);
        const response = await AuthService.saveCategory(requestData);
        if(response?.statusMsg==="SUCCESS"){
            // window.location.reload();
            if(values.id){
                setIndex(0);
            }
            return true;
        }else{
            return false;
        }
    }
    return (
        
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={CategoryScreenSchema}
            onSubmit={async (values,{resetForm})=>{
                const isSubmitted=await checkAndSend(values);
                if(isSubmitted){ 
                    // setInitialValue(new CategoryScreenModel());
                    resetForm();
                }
                
            }}
                // ,checkAndSend}
        >
            {({ isSubmitting, errors, isValid, dirty }) => (

                <Form>
                <Grid container spacing={2} className='designHeader'>
                    <Grid item lg={6} sm={12} md={12} >
                    <h1 className="header-title">Add Category</h1>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='design'>
                    <Grid item lg={6} sm={12} md={12} >
                        <FormikControl
                            control="textfield" name="title" label="Item Name" placeholder="Item name" />
                    </Grid>
                </Grid>      
                <Grid container spacing={2} className='design'>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="textfield" name="short_description" label="Sort Description" placeholder="About item in-sort" multiline 
                            rows={4}/>
                    </Grid>
                </Grid>      
                <Grid container spacing={2} className='design'>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="textfield" name="description" label="Long Description" placeholder="Detail about item" multiline 
                            rows={4}/>
                    </Grid>
                </Grid>    
                
                <Grid container spacing={2}>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="switch" name="category_availability" label="Availibility" placeholder="Item availibility" checked={true} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='design'>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="uploadImage" name="image" label="Image" placeholder="Category image" />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="button" name="savebtn" label="Save"  color="primary"/>
                    </Grid>
                </Grid>    
                
                </Form>
            )}
        </Formik>

    )
  
}
