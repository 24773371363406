import axios from "axios";
import { msgTypes } from "../../constants/msgTypes";
import { RouteType } from "../../constants/RouteType";
import { LoginModel } from "../../model/customModel/LoginModel";
import { ResponseModel } from "../../model/utilModels/ResponseModel";
import ApiUrl from "../shared/ApiUrl";
import { ItemScreenModel } from "../../model/customModel/ItemScreenModel";
import { RegistrationScreenModel } from "../../model/customModel/RegistrationScreenModel";
import { CategoryScreenModel } from "../../model/customModel/CategoryScreenModel";
import { CategoryListSCreenModel } from "../../model/customModel/CategoryListScreenModel";
import { PaginatorModel } from "../../model/utilModels/PaginatorModel";

var CryptoJS = require("crypto-js");

export class AuthService {
  constructor() { }

  static autanticateUser = async (authData: LoginModel) => {
    const url = RouteType.ADMIN + RouteType.AUTH + RouteType.LOGIN;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(authData))
    return response;
  };
  static saveItem = async (itemData: ItemScreenModel) => {
    const url = RouteType.ADMIN + RouteType.PRODUCT + RouteType.STORE;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(itemData))
    return response;
  };
  static deleteItem = async (itemData: ItemScreenModel) => {
    const url = RouteType.ADMIN + RouteType.PRODUCT + RouteType.DELETE;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(itemData))
    return response;
  };
  static getItemList = async (requestBody:PaginatorModel | string) => {
    const url = RouteType.ADMIN + RouteType.PRODUCT;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestBody))
    return response;
  };
  static saveCategory = async (itemData: CategoryScreenModel) => {
    const url = RouteType.ADMIN + RouteType.CATEGORY + RouteType.STORE;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(itemData))
    return response;
  };
  static deleteCategory = async (itemData: CategoryScreenModel) => {
    const url = RouteType.ADMIN + RouteType.CATEGORY + RouteType.DELETE;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(itemData))
    return response;
  };
  static getCategorylist = async (requestBody:PaginatorModel | string) => {
    const url = RouteType.ADMIN + RouteType.CATEGORY;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(requestBody))
    return response;
  };
  static saveRegistration = async (itemData: RegistrationScreenModel) => {
    const url = RouteType.ADMIN + RouteType.AUTH + RouteType.REGISTER;
    const response = await AuthService.postCryptoRequest(url, JSON.stringify(itemData))
    return response;
  };

  //Encryption
  static getEncryptText = async (plaintText: string) => {
    let _key = CryptoJS.enc.Utf8.parse(msgTypes.APP_SECRET_KEY.KEY);
    let _iv = CryptoJS.enc.Utf8.parse(msgTypes.APP_SECRET_KEY.IV);
    let encrypted = CryptoJS.AES.encrypt(
      plaintText, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  //Decryption
  static getDecryptText = async (encrypted: string) => {
    let _key = CryptoJS.enc.Utf8.parse(msgTypes.APP_SECRET_KEY.KEY);
    let _iv = CryptoJS.enc.Utf8.parse(msgTypes.APP_SECRET_KEY.IV);

    let decrypted = CryptoJS.AES.decrypt(
      encrypted, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return decrypted
  }


  //For Encryprtion and Decryption
  static postCryptoRequest = async (apiUrl: string, requestJsonStr: string | FormData) => {
    console.log("request body- ",requestJsonStr);
    
   
    apiUrl = ApiUrl.baseUrl + apiUrl ;

    //prepare encrypted string request body
    let requestObj:any = null;
    if(!(requestJsonStr instanceof FormData)){
      if(requestJsonStr.length>0){
        const encrypteddata = await AuthService.getEncryptText(requestJsonStr)
        requestObj = {
          data: encrypteddata
        }
      }
    }else{
      requestObj = requestJsonStr;
    }

    const encryptRes = await axios.post<string>(apiUrl, requestObj, { responseType: 'text' as 'json' })
    const decryptRes = await AuthService.getDecryptText(JSON.parse(encryptRes?.data));

    let response = new ResponseModel();
    response = JSON.parse(decryptRes);
    console.log("Response-", response);

    return response;
  }




}