
import React from 'react'
import Header from './Header'
import SideMenuBar from './SideMenuBar'
import FooterMenu from './FooterMenu'
import { Outlet } from 'react-router-dom'
import './SideMenuBar.scss'

export default function LayoutContent() {
  return (
    <div>
      <Header />
      <div className="d-flex flex-column flex-root">
        {/* <!--begin::Page--> */}
        <div className="d-flex flex-row flex-column-fluid page">
          <SideMenuBar />
          <div className="d-flex flex-column flex-row-fluid wrapper " id="kt_wrapper ">
            {/* <!--begin::Header--> */}
            <div id="kt_header " className="header header-fixed ">
              {/* <!--begin::Container--> */}
              <div className=" container-fluid d-flex align-items-stretch justify-content-between ">
                {/* <!--begin::Header Menu Wrapper--> */}
                <div className="header-menu-wrapper header-menu-wrapper-left " id="kt_header_menu_wrapper ">
                  {/* <!--begin::Header Menu--> */}
                  <div id="kt_header_menu " className="header-menu header-menu-mobile header-menu-layout-default ">

                  </div>
                  {/* <!--end::Header Menu--> */}
                </div>
                {/* <!--end::Header Menu Wrapper--> */}

                {/* <!--begin::Topbar--> */}
                <div className="topbar ">
                  {/* <!--begin::User--> */}
                  <div className="topbar-item ">
                    <div className="btn btn-icon btn-icon-mobile btn-clean d-flex align-items-center btn-lg px-2 " id="kt_quick_user_toggle ">
                      <span className="text-muted font-weight-bold font-size-base d-md-inline mr-1">Hi,</span>
                      <span className="text-dark-50 font-weight-bolder font-size-base d-md-inline mr-3">Cottage kitchen User</span>
                    </div>
                  </div>
                  {/* <!--end::User--> */}
                </div>
                {/* <!--end::Topbar--> */}
              </div>
              {/* <!--end::Container--> */}
            </div>
            <Outlet></Outlet>
          </div>
        </div>
        {/* <!--end::Page--> */}
      </div>
      <FooterMenu />

    </div>
  )
}
