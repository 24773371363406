import React, { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './styles/RegisterScreen.css';
import DescriptionIcon from '@mui/icons-material/Description';
import { red } from "@mui/material/colors";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { RegistrationScreenModel } from "../model/customModel/RegistrationScreenModel";
import { RegistrationScreenSchema } from "../schema/RegistrationScreenSchema";
import { Avatar, Grid } from "@mui/material";
import FormikControl from "../components/wrapperComponents/FormikControl";
import { AuthService } from "../services/auth/AuthService";
import { useNavigate } from "react-router-dom";


const Register = () => {
  const logo = require("../assests/images/login_person.png");
  const [initialValue,setInitialValue]=useState(new RegistrationScreenModel());

  const navigate = useNavigate();
  const prepareData=(values:RegistrationScreenModel)=>{
    const data=new RegistrationScreenModel();
    data.name=values.name?values.name:'';
    data.address=values.address?values.address:'';
    data.state=values.state?values.state:'';
    data.zip=values.zip?values.zip:'';
    data.mobile=values.mobile?values.mobile:'';
    data.email=values.email?values.email:'';
    data.password=values.password?values.password:'';
    return data;
  }

  const check = async (values: RegistrationScreenModel )=>{  
    if(values.password === values.confirmpassword){
      const requestData=await prepareData(values);
      const response=await AuthService.saveRegistration(requestData);
      // alert(response);
      if(response.statusMsg === 'SUCCESS'){
        alert("Registration done successfully.");
        navigate("/");        
      } else {
        // setErrors({ username: 'Invalid email or password' });
        alert("Some problem");
      }
    }else{
      alert("Password and Confirm password must be same.");
    }
};
  // const check=(values: any)=>{
  //   alert("ok");
  // }
return(
  <div className="bodyLogin" >
    <div className='wrapper1'>
       
       <div style={{ display: 'flex', justifyContent: 'center'}}>
       {/* <DescriptionIcon style={{ fontSize: 60}}/> */}
       <h1> Registartion Form</h1>
       </div>

      <Formik
        initialValues={initialValue}
        validationSchema={RegistrationScreenSchema}
        onSubmit={check}
       >
        {({ isSubmitting, errors, isValid, dirty }) => (
          
          <Form>
           
             <div style={{marginBottom:20}}></div>
             
                <Grid container spacing={2} className="wrapper3">
                   <Grid item lg={12} sm={12} md={12} >
                       <FormikControl control="textfield"
                       label="Firm name"
                       requiredField
                       name="name"
                       placeholder="Enter firm name" 
                       size="small"
                       />
                   </Grid>
                </Grid>

                <Grid container spacing={2}  className="wrapper3">
                    <Grid item lg={12} sm={12} md={12} >
                        <FormikControl control="textfield"
                            label="Owner name"
                            requiredField
                            name="ownername"
                            placeholder="Enter owner name"
                            size="small"
                            />
                    </Grid>
                </Grid>

                <Grid container spacing={2}  className="wrapper3">
                   <Grid item lg={12} sm={12} md={12} >
                       <FormikControl control="textfield"
                           label="Address"
                           requiredField
                           name="address"
                           placeholder="Address"
                           size="small"
                           />
                   </Grid>
                </Grid>

                <Grid container spacing={2}  className="wrapper3">
                   <Grid item lg={6} sm={12} md={12} >
                       <FormikControl control="textfield"
                           label="State"
                           requiredField
                           name="state"
                           placeholder="State"
                           size="small"
                           />
                   </Grid>
                   <Grid item lg={6} sm={12} md={12} >
                       <FormikControl control="textfield"
                           label="PINCODE"
                           requiredField
                           name="zip"
                           placeholder="PINCODE"
                           size="small"
                           />
                   </Grid>
                </Grid>

                <Grid container spacing={2}  className="wrapper3">
                  <Grid item lg={12} sm={12} md={12} >
                      <FormikControl control="textfield"
                          label="Mobile No."
                          requiredField
                          name="mobile"
                          placeholder="Enter mobile no."
                          size="small"
                          />
                  </Grid>
                </Grid>

                <Grid container spacing={2}  className="wrapper3">
                  <Grid item lg={12} sm={12} md={12} >
                      <FormikControl control="textfield"
                          label="Email Id"
                          requiredField
                          name="email"
                          placeholder="Enter email id"
                          size="small"
                          />
                  </Grid>
                </Grid>

                <Grid container spacing={2}  className="wrapper3">
                  <Grid item lg={6} sm={12} md={12} >
                      <FormikControl control="passwordfield"
                          label="Password"
                          requiredField
                          name="password"
                          placeholder="New password"
                          size="small"
                          />
                  </Grid>
                  <Grid item lg={6} sm={12} md={12} >
                      <FormikControl control="passwordfield"
                          label="Re-confirm Password"
                          requiredField
                          name="confirmpassword"
                          placeholder="Retype password"
                          size="small"
                          />
                  </Grid>
                </Grid>

                <Grid container spacing={2}  className='wrapper2'>    
                  <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="button" name="savebtn" label="Save" placeholder="Item Type" color="primary"/>
                    </Grid>
                </Grid>    
               </Form>
        )}
      </Formik>

    </div>
  </div>
);

};
export default Register;
