import { Formik, Form} from 'formik'
import React, { useEffect, useState } from 'react'
import NoRecord from '../components/sharedComponent/noRecord/NoRecord'
import CustomTableGrid from '../components/uiComponents/CustomTableGrid'
import ReactTable from '../components/wrapperComponents/ReactTable'
import { PaginatorModel } from '../model/utilModels/PaginatorModel'
import { ResponseModel } from '../model/utilModels/ResponseModel'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { msgTypes } from '../constants/msgTypes'
import { conformationAlert } from '../services/shared/alert'
import { AuthService } from '../services/auth/AuthService'
import { CategoryScreenModel } from '../model/customModel/CategoryScreenModel'

const CategoryViewScreen = (props:any) => {
    const {setIndex, updateRecord} = props;
    const [categoryList, setCategoryList] = useState(new ResponseModel());
    const [loading, setLoading] = useState(false);
    const [noRecord, setNoRecord] = useState(false);
    const [selectedValues, setSelectedValues] = useState(new CategoryScreenModel())
    const paginatorModel: PaginatorModel = new PaginatorModel();
    const [pagination, setPagination] = useState({
        pageSize: paginatorModel.pageSize,
        pageNumber: paginatorModel.pageNumber
    })

    //Begin:: UseEffect Hooks 

    useEffect(() => {
        getCategoryListData();
        }, []);

    // useEffect(() => {
    //     if (selectedValues)
    //         getCategoryListData();
    //     }, [pagination.pageNumber, pagination.pageSize])

    //End:: UseEffect Hooks 


    const changeTabIndex = () => {
        setIndex(1);
    }


    //Prepare request body to get blog list
    const prepareRequest = () => {
        const requestBody = new PaginatorModel();
        requestBody.pageNumber = pagination.pageNumber;
        requestBody.pageSize = pagination.pageSize;

        return requestBody;
    }

    //Get Blog list
    const getCategoryListData = async () => {
        // const requestBody = prepareRequest();
        // setLoading(true)
        // const res = await AuthService.getCategorylist(requestBody);
        // setLoading(false)
        // if (res.statusMsg === msgTypes.SUCCESS.msg) {
        //     setBlogList(res)
        // }
        const requestBody = prepareRequest();
        const response = await AuthService.getCategorylist(requestBody);
        console.log(response);
        // if(response.statusMsg === 'SUCCESS'){
            // rows = [response.data.length];
            // for(let i=0;i<response.data.length;i++){
            //     console.log(response.data[i].title);
            //     rows[i]=createData(response.data[i].id, response.data[i].title, baseUrl+response.data[i].image, response.data[i].short_description);
            //     console.log(rows[i]);
            // }
            // setLoading(false)
            if (response.statusMsg === msgTypes.SUCCESS.msg) {
              setCategoryList(response)
              
            }
        // }
    }


    const onPageChangeHandler = (page: number, rowsPerPage: number, values: CategoryScreenModel) => {
    setSelectedValues(values);
    setPagination({
    pageNumber: page,
    pageSize: rowsPerPage,
    })
    }

    //Open Form To edit Record
    const editRecord = (record: CategoryScreenModel) => {
    updateRecord(record);
    changeTabIndex();
    }

    const deleteRecord = async (record: CategoryScreenModel) => {
        const requestData: CategoryScreenModel = new CategoryScreenModel();
        requestData.id = record.id

        const deleteFlag = await conformationAlert(msgTypes.CONFORMATION_MESSAGE.DELETE_RECORD, msgTypes.CONFORMATION_ICON.ERROR);
        if(deleteFlag) {
            const res = await AuthService.deleteCategory(requestData);
            if (res.statusMsg === msgTypes.SUCCESS.msg) {
                getCategoryListData();
            }
        }
    }


    const initialValues = {}
    const onSubmit = () => {
        getCategoryListData();
    }
    return (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          //validationSchema={LoadMoneySchema}
          onSubmit={onSubmit}
        >
          {
            formik =>
            (
              <Form>
    
                <>
                  {
                    (categoryList.data.tbody && categoryList.data.tbody.length) ? (
                      <>
                        <CustomTableGrid
                          item={
                            <ReactTable
                              tableData={categoryList}
                              formikValue={formik}
                              onPageChange={onPageChangeHandler}
                              loading={loading}
                              noRecordFound = {true}
                              actions={[{ type: "icon", icon: <EditIcon />, actionFunc: editRecord },
                              { type: "icon", icon: <DeleteIcon color='error' />, actionFunc: deleteRecord }
                              ]}
                              positionActionsColumn="last"
                            />
                          }
                        />
                      </>
                    ) : (noRecord && <NoRecord />)
                  }
                </>
              </Form>
            )
          }
        </Formik>
      )
}


export default CategoryViewScreen;