import * as Yup from "yup";

export const RegistrationScreenSchema = Yup.object().shape({
    name: Yup.string().required("Enter firm name"),
    address: Yup.string().required("Enter firm address"),
    email: Yup.string().required("Enter email id").matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,"Invalid email format"),
    mobile: Yup.string().required("Enter mobile no."),
    password: Yup.string().required("Enter password"),
    confirmpassword: Yup.string().required("Enter confirm password"),

})