export const msgTypes={
    APP_SECRET_KEY:
    {
        KEY: "ACC62A67A3D055E6AF68BD9D3ED9A519",
        IV: "608538a015674f17"
    },
    IMAGE_EXTENSIONS:["jpg","png","jpeg"],
    EMPTY_STR:"",
    ACCESS_TOKEN: "accessToken",
    
    SUCCESS:
    {
        msg: "SUCCESS"
    },
    OK_KEY:"OK",
    success:"Success",
    error:"Error",
    SOMETHING_WENT_WRONG: "SOMETHING WENT WRONG",

    SUPPORTED_IMAGE_FORMAT: ["image/jpg", "image/jpeg", "image/gif", "image/png"],

    CONFORMATION_MESSAGE:
    {
        DELETE_RECORD: "Delete record?"
    },
    CONFORMATION_ICON:
    {
        ERROR: "error",
        WARNING: "warning"
    },
};