import { RouteType } from "../../constants/RouteType";
import { AuthService } from "../auth/AuthService";


export class UploadFileService {
  constructor() { }

  //To get uploaded image url 
  static getImagePath = async (formDate: FormData) => {
    const url = RouteType.ADMIN + RouteType.FILE;
    const response = AuthService.postCryptoRequest(url, formDate);
    return response;
  };

}