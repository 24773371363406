import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { ResponseModel } from './model/utilModels/ResponseModel';

import { LocalStorageService } from './services/auth/LocalStorageService';
import showAlert from './services/shared/alert';
import { AuthService } from './services/auth/AuthService';
import { UtilService } from './services/shared/UtilService';
import { msgTypes } from './constants/msgTypes';

axios.interceptors.request.use(
 
  async (config) => {
    const token = await LocalStorageService.getToken();
    if (token) {
      if (token != null && token != 'null') {
        config.headers['Authorization'] = "Bearer " + token;
        return config;
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
 
//Interceptor for response body
axios.interceptors.response.use(
  async (response?: any) => {
    let responseValue = new ResponseModel();
    try {
 
      const decryptRes = await AuthService.getDecryptText(JSON.parse(response.data));
      responseValue = JSON.parse(decryptRes);
 
      if (responseValue?.statusMsg === msgTypes.SUCCESS.msg) {
        if (responseValue.displayAlert === true) {
          showAlert(responseValue.statusMsg, responseValue.statusRemark, msgTypes.success, msgTypes.OK_KEY);
        }
        return (response);
      }else{
        if (responseValue.displayAlert === true) {
          showAlert(responseValue.statusMsg, responseValue.statusRemark, msgTypes.error, msgTypes.OK_KEY);
        }
      }
    } catch (error) {
      showAlert(responseValue.statusMsg, responseValue.statusRemark, msgTypes.error, msgTypes.OK_KEY);
    }
  },
  (error) => {
    showAlert(UtilService.upperCase(msgTypes.error), msgTypes.SOMETHING_WENT_WRONG, msgTypes.error, msgTypes.OK_KEY);
    return Promise.reject(error);
  }
);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
