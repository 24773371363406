
import { useEffect, useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error';
// import ApiUrl from 'services/shared/ApiUrl';
import { msgTypes } from '../../constants/msgTypes';

const ImagePreview = ({ file, fileName }) => {
  const [preview, setPreview] = useState(null)
  
  useEffect(()=>{
    if(typeof(file)==='object'){
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            setPreview(reader?.result)
          }
        }
    }
    // if(typeof(file)==='string'){
    //     setPreview(ApiUrl.fileBaseUrl+"/"+file)
    //     const fileArray = file.split('/');
    //     const fileName1 = fileArray[fileArray.length-1];
    //     fileName = fileName1;
    // }
  },[file])
  
  function getExtension() {
    if(fileName!=null){
      return fileName?.split('.').pop()
    }else{
      return "";
    }
  }

  return (
    <div>
      {/* Execute when file uploaded by selecting the image */}
      {typeof(file)==='object' && msgTypes.IMAGE_EXTENSIONS.includes(getExtension()?.toLowerCase()) && fileName ? 
          <img src={preview} alt='preview' width="80px" height="80px" /> 
          : fileName=='' || fileName==undefined ? "": <ErrorIcon color="error"  sx={{marginLeft:"42px"}}/>}

      {/* Execute when user update the record */}
      {typeof(file)==='string' ? 
          <img src={preview} alt='preview' width="80px" height="80px" /> 
          : ""}
      
    </div>
  )
}

export default ImagePreview
