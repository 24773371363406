import * as Yup from 'yup';
import { Validation } from '../constants/validation';
import { msgTypes } from '../constants/msgTypes';

// const size = 102400; //100KB
// const type = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

export const ItemScreenSchema=Yup.object().shape({
    
    title: Yup.string().required("Enter item name"),
    price: Yup.string().required("Enter item rate").matches(/^\d+$/,"Invalid item rate"),
    // images: Yup.array().of(Yup.mixed().required('Please upload an image')),

    images: Yup.array().of(
        Yup.mixed()
          .nullable()
          .required(Validation.required.message.replaceAll("^", "Image"))
          .test(
            "FILE_FORMAT",
            "Uploaded file has unsupported format",
            (value:any) =>
              !value ||
              (value && msgTypes.SUPPORTED_IMAGE_FORMAT.includes(value?.type))
          )
          .test(
            "FILE_SIZE",
            "Uploaded file is too big",
            (value:any) => !value || (value && value.size <= 1024 * 1024)
          )
      ),
})