import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import './App.css';
import LoginScreen from './screen/LoginScreen';
import RegistrationScreen from './screen/RegistrationScreen';
import DashboardScreen from './components/dashboard/DashboardScreen';
import { MainDashboard } from './screen/MainDashboard';
import { CategoryScreen } from './screen/CategoryScreen';
import { ItemScreen } from './screen/ItemScreen';
import { CategoryListScreen } from './screen/CategoryListScreen';
import { CategoryTabScreen } from './screen/CategoryTabScreen';
import { ItemTabScreen } from './screen/ItemTabScreen';
import LayoutContent from './screen/layout-pages/LayoutContent';

function App() {
  return (
    <BrowserRouter>
   <Routes>
        <Route path='/' element={<LoginScreen />}></Route>
        <Route path='register' element={<RegistrationScreen />}></Route>
        <Route path='dashboard' element={<LayoutContent />}>
        {/* <Route path='dashboard' element={<DashboardScreen />}> */}
            {/* <Route path='' element={<MainDashboard />} /> */}
            <Route path='category' element={<CategoryScreen />} />
            <Route path='category_tab' element={<CategoryTabScreen />} />
            <Route path='item_tab' element={<ItemTabScreen />} />
            <Route path='item' element={<ItemScreen />} />
            <Route path='category_list' element={<CategoryListScreen />} />
        </Route>
   </Routes>
   </BrowserRouter>
  );
}

export default App;
