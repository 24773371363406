
import { Box, InputAdornment, TextField } from "@mui/material";
import TextError from "./TextError";
import MuiLabel from "./MuiLabel";
import { ErrorMessage, Field, useFormikContext } from "formik";
// import ImagePreview from "components/uiComponents/ImagePreview";
import React, { useEffect, useRef, useState } from "react";
import ImagePreview from "../uiComponents/ImagePreview";

function MuiUploadImage(props) {

    const { label, name, ...rest } = props;
    const { setFieldValue, values, errors } = useFormikContext();
    const [fileName, setFileName] = useState(null);
    const inputFile = useRef(null);

    // useEffect(() => {
    //     console.log("values",values.image);
       
    // }, [values.image])

    return (

        <Field name={name} {...rest}>
            {({ field }) => {
                const { value, ...restField } = field;
                return (
                    <React.Fragment>
                        <MuiLabel {...props} />
                        <TextField type="file"
                            id={name} {...rest} {...restField}
                            fullWidth
                            ref={inputFile}
                            size='small'
                            accept="image/*"    
                            multiple={true}
                            name={name}
                            onChange={(event) => {
                                setFieldValue(props.name, event.target.files[0]);
                                setFileName(event.target.files[0].name);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Box width={55}> {values?.image == '' || values.image == undefined ? "" : <ImagePreview file={values?.image} fileName={fileName} />} </Box>
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                style: {
                                    padding: '5px 6px',
                                    paddingBottom: '12px'
                                }
                            }}
                        >
                        </TextField>

                        <Box className='error-box'>
                            <ErrorMessage name={name} component={TextError} />
                        </Box>
                    </React.Fragment>
                );
            }}

        </Field>

    )
}

export default MuiUploadImage