import React, { useEffect, useState } from 'react'
import FormikControl from "../components/wrapperComponents/FormikControl";
import { FieldArray, Form, Formik, FormikErrors } from 'formik';
import { Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography, useTheme } from '@mui/material';
import "./styles/ItemScreen.css";
import { Navigate, useNavigate } from 'react-router-dom';
import { ItemScreenModel } from '../model/customModel/ItemScreenModel';
import { ItemScreenSchema } from '../schema/ItemScreenSchema';
import { AuthService } from '../services/auth/AuthService';
import { UploadFileService } from '../services/shared/UploadFileService';
import DeleteIcon from '@mui/icons-material/Delete';
import { msgTypes } from '../constants/msgTypes';
import { string } from 'yup';

export const ItemScreen = (props:any) => {
    const {setIndex, updateRecord} = props;
    const [initialValue, setInitialValue] = useState(new ItemScreenModel());
    const [categoryList, setCategoryList] = useState([]);
    let flag = true;
    useEffect(() => {
        if (updateRecord) {
          if(updateRecord.id) {   

            // manage category
            if(flag){
              let services = updateRecord.timings;//"['service1', 'service2', 'service3']"
              services = services.replace(/'/g, '"'); //replacing all ' with "
              services = JSON.parse(services);
              console.log(services);
              updateRecord.timings=services;
              // console.log(updateRecord.category_id);
              // updateRecord.id=updateRecord.category_id;
              // updateRecord.id=313833596;
              flag?flag=false:flag=true;
            }

            //manage image
            const imageArray=[];
            imageArray.push(updateRecord.images);
            updateRecord.images=imageArray;

            setInitialValue(updateRecord)
          }
        }
      }, [updateRecord])
    //   const timeList = [
    //     {"key":"Breakfast", "value":"Breakfast"}, 
    //     {"key":"Lunch", "value":"Lunch"}, 
    //     {"key":"Snacks", "value":"Snacks"}, 
    //     {"key":"Dinner", "value":"Dinner"}];
        const timeList = [
            "Breakfast", 
            "Lunch", 
            "Snacks", 
            "Dinner"];
      
    
    useEffect(()=>{
        loadCategory();
        },[]);

    const loadCategory= async ()=>{
      const response = await AuthService.getCategorylist(msgTypes.EMPTY_STR);
      // alert("OK");
      if(response.statusMsg === 'SUCCESS'){
        setCategoryList(response.data);
      }else {
          alert("Some problem");
        }
    }   

    const addImage = (values: ItemScreenModel, setFieldValue: { (field: string, value: any, shouldValidate?: boolean): Promise<void | FormikErrors<ItemScreenModel>>; (arg0: string, arg1: any): void; }) => {
      values.images.push(values.images.length);
      setFieldValue("images", values.images);
    };
    
    const navigate = useNavigate();

    const prepareData = async (values:ItemScreenModel)=>{  
        const data=new ItemScreenModel();
        // data.image=values.image?values.image:'';
        data.timings=values.timings?values.timings:[];
        data.category_id=values.category_id?values.category_id:0;
        data.title=values.title?values.title:'';
        data.price=values.price?values.price:'';
        data.description=values.description?values.description:'';
        data.qty=values.qty?values.qty:0;
        data.id=values.id?values.id:'';

        data.images=[];
        for(let i=0;i<values.images.length;i++){
            if (values.images) {
                const formData = new FormData();
                formData.append("file", values.images[i] as any);
                const imageurlData = await UploadFileService.getImagePath(formData);
                data.images.push(imageurlData.data.fileName);
                // data.images=imageurlData.data.fileName;
            } else {
                data.images = [];
            }
        }
        
        return data;
    }       
    const checkAndSend = async (values: ItemScreenModel)=>{
        const requestData=await prepareData(values);
        const response=await AuthService.saveItem(requestData);
        // alert("OK");
        if(response.statusMsg === 'SUCCESS'){
            window.location.reload();
        }else {
            alert("Some problem");
        }
    }
    
    
    return (
        
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={ItemScreenSchema}
            onSubmit={checkAndSend}
        >
            {({ isSubmitting, errors, isValid, values, dirty, setFieldValue }) => (

                <Form>
                <Grid container spacing={2} className='designHeader'>
                    <Grid item lg={6} sm={12} md={12} >
                    <h1 className="header-title">Add Item</h1>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='design'>
                    <Grid item lg={6} sm={12} md={12} >
                        <FormikControl
                            control="textfield" name="title" label="Item Name" placeholder="Item name" />
                    </Grid>
                </Grid>   
                <Grid container spacing={2} className='design'>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="textfield" name="description" label="Description" placeholder="Detail about item" multiline 
                            rows={4}/>
                    </Grid>
                </Grid>     
                <Grid container spacing={2} className='design'>
                    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl control='autocomplete' 
                            label="Under category"
                            name="category_id"
                            placeholder="Select Category"
                            requiredField={true}
                            options={categoryList}
                            // onChange={(e) => { loadStateList({ "country": e.countryIdentifier }) }}
                            keyfield="title"
                            valuefield="id"
                        />
                    </Grid>     
                </Grid>   
                
                <Grid container spacing={2} className='design'>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl control='select'
                            multiple={true}
                            label="Availability Time"
                            name="timings"
                            placeholder="Select time"
                            requiredField={true}
                            options={timeList}
                            // onChange={(e) => { loadStateList({ "country": e.countryIdentifier }) }}
                            // key="name"
                            // value="timeIdentifier"
                            // keyfield="name"
                            // valuefield="timeIdentifier"
                        />
                    </Grid>                                
                </Grid>
                    
                <Grid container spacing={2} className='design'>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="textfield" name="price" label="Rate" placeholder="Item rate" />
                    </Grid>
                </Grid> 
                <Grid container spacing={2}>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="switch" name="itemavailability" label="Availibility" checked={true} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} >    
                    <Grid item  lg={12} sm={12} md={12} >
                        <FieldArray name="images">
                          {({ push, remove }) => (
                            <>
                              {values.images.map((file, index) => (
                                <Grid
                                  item
                                  xs={9}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                  key={index}
                                  className="image-upload-grid"
                                >
                                  <FormikControl
                                    control="uploadImage"
                                    name={`images.${index}`}
                                    label="Image"
                                    requiredField
                                    disabled={false}
                                    className="image-upload-field"
                                    
                                  />
                                  <DeleteIcon
                                    onClick={() => remove(index)}
                                    color="error"
                                  />
                                 </Grid>                                
                              ))}                              
                            </>                            
                          )}
                        </FieldArray>                                                
                    </Grid>
                </Grid> 

                    <Grid container  spacing={2}>
                      
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          className="add-more-images-button"
                          onClick={() => addImage(values, setFieldValue)}
                          color='warning'
                          size='small'
                        >
                          + Add More Images
                        </Button>
                      </Grid>
                    </Grid>

                    
                <Grid container spacing={2} className='design-top'>    
                    <Grid item  lg={6} sm={12} md={12} >
                        <FormikControl
                            control="button" name="savebtn" label="Save"  color="primary"/>
                    </Grid>
                </Grid>    
                
                </Form>
            )}
        </Formik>

    )
}
