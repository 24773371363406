import * as React from 'react';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/material';

export default function CustomSwitch(props) {
    const { handleBlur, handleChange} = props.formikValue;
    return (
        <Box mt={2}>
            <Switch
                name={props.name}
                disabled={props.disabled}
                onChange={handleChange}
                value={props.value}
                checked={props.value}
            />
        </Box>
    );
}