import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TocIcon from '@mui/icons-material/Toc';
import AddIcon from '@mui/icons-material/Add';

import { CategoryScreen } from './CategoryScreen';
import CategoryViewScreen from './CategoryViewScreen';
import { CategoryScreenModel } from '../model/customModel/CategoryScreenModel';
import Grid from '@mui/material/Grid';
import "./styles/CategoryScreen.css"
import { ItemScreen } from './ItemScreen';
import ItemViewScreen from './ItemViewScreen';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  function indexProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const ItemTabScreen = () => {

    const [value, setValue] = React.useState(0);
    const [updateValue, setUpdateValue] = React.useState(new CategoryScreenModel());
  
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      setUpdateValue(new CategoryScreenModel())
    };
  
    const changeTabIndex = (data: number) =>{
      setValue(data);
      //setUpdateValue(new BlogModel());
    }
  
    //to get update record value
    const getUpdateRecordValue = (categoryModel: CategoryScreenModel) =>{
      setUpdateValue(categoryModel);
    }  

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} className='designHeader'>
          <Grid item lg={6} sm={12} md={12} >
          <h1 className="header-title">Item</h1>
          </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: '#ebedf3' }}>
        <Tabs className='tab-list' value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab icon={<TocIcon />} label="Item List" iconPosition='start' {...indexProps(0)} />
          <Tab icon={<AddIcon />} label="Add Item"  iconPosition='start' {...indexProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <ItemViewScreen  setIndex={changeTabIndex} updateRecord={getUpdateRecordValue}></ItemViewScreen>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ItemScreen setIndex={changeTabIndex} updateRecord={updateValue}></ItemScreen>   
      </CustomTabPanel>
    </Box>
  );
}

