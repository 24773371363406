import * as Yup from "yup";
 
export const LoginSchema=Yup.object().shape({
        
     email: Yup.string().required("Email id Is Required")
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,"Invalid email format"),
 
    //userid:Yup.string().required("Enter user id.").min(4,"Minimum 4 characters long."),
 
    password:Yup.string().required("Enter password."),
})